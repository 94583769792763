<template>
  <TournerListeMainVue :rows="tournees" />
</template>

<script>
import TournerListeMainVue from '@/views/afridia/tourners/liste/TournerListeMainVue.vue'

export default {
  name: 'MainVue',
  components: { TournerListeMainVue },
  data() {
    return {
      tournees: [],
    }
  },
}
</script>

<style scoped>

</style>
